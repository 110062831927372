<template>
  <v-app>
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{
          'alert-error': alertType == 'error',
          'alert-success': alertType == 'success'
        }"
      >
        {{ alertMessage }}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
        <div class="mb-8 mt-8 text-center">
          <h1>Terminal Benefit Rate</h1>
        </div>
        <v-form ref="terminalBenefitRate" v-model="valid">
          <v-row no-gutters>
            <v-col>
              <SelectField
                :items="exitReasons"
                v-model="formData.ExitReason"
                :label="'Exit Reason'"
                :returnObject="true"
                :itemText="'name'"
                :itemValue="'id'"
              />
            </v-col>
            <v-col>
              <SelectField
                :items="salaryComponents"
                v-model="formData.SalaryComponent"
                :label="'Salary Component'"
                :returnObject="true"
                :itemText="'description'"
                :itemValue="'id'"
                @onChange="getInputLabel"
              />
            </v-col>
            <v-col>
              <TextField
                v-model="formData.rate"
                :type="'number'"
                :label="label"
              />
            </v-col>
            <v-col cols="12" class="d-flex justify-end mb-6">
              <Button
                :label="'Add'"
                :btnType="'Submit'"
                :disabled="!valid"
                @onClick="addBenefit"
                class=""
              />
            </v-col>

            <v-row>
              <div class="col-sm-12 ml-auto mr-auto">
                <v-col v-if="TerminalBenefitRates.length > 0" class="mb-6">
                  <v-data-table
                      loading-text="Loading Terminal Benefit Rate... Please wait"
                      :loading="!TerminalBenefitRates.length"
                    :headers="rateHeader"
                    :items="TerminalBenefitRates"
                  >
                    <template v-slot:item.actions="{ item }">
                      <v-icon small @click="removeRate(item)">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-col>
              </div>
            </v-row>

            <v-col cols="12" class="d-flex justify-end">
              <Button
                :label="'Submit'"
                :btnType="'Submit'"
                @onClick="dialog = true"
                :disabled="!valid"
                :isLoading="isSaving"
                class="mr-4"
              />

              <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
    <ConfirmationDialog :title="'Are you sure you want to submit?'" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
  </v-app>
</template>
<script>
import SelectField from '@/components/ui/form/SelectField.vue'
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { benefitService, salaryComponentService } from '@/services'
import { CREATE_TERMINALRATE } from '@/store/action-type'
export default {
  components: {
    SelectField,
    TextField,
    Button,
    ConfirmationDialog
  },
  data () {
    return {
      dialog: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      valid: false,
      isSaving: false,
      formData: {
        ExitReason: '',
        SalaryComponent: '',
        rate: 0
      },
      label: 'Rate',
      exitReasons: [],
      salaryComponents: [],
      TerminalBenefitRates: [],
      rateHeader: [
        { text: 'Exit Reason', value: 'ExitReason' },
        { text: 'Salary Component', value: 'salaryComponent' },
        { text: 'Rate', value: 'rate' },
        { text: 'Actions', value: 'actions' }
      ]
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => { this.alert = false }, 5000)
    },
    addBenefit () {
      this.TerminalBenefitRates.push({
        ExitReasonId: this.formData.ExitReason.id,
        ExitReason: this.formData.ExitReason.name,
        SalaryComponentId: this.formData.SalaryComponent.id,
        salaryComponent: this.formData.SalaryComponent.description,
        rate: this.formData.rate
      })
    },
    removeRate (item) {
      let index = null
      for (let i = 0; i < this.TerminalBenefitRates.length; i++) {
        if (this.TerminalBenefitRates[i].id === item.id) {
          index = i
          break
        }
      }
      console.log(index)
      this.TerminalBenefitRates.splice(index, 1)
    },

    onSave () {
      let data = []
      this.TerminalBenefitRates.forEach(rate => {
        return data.push({
          ExitReasonId: rate.ExitReasonId,
          SalaryComponentId: rate.SalaryComponentId,
          rate: rate.rate
        })
      })
      this.isSaving = true
      this.$store
        .dispatch(CREATE_TERMINALRATE, data)
        .then(() => {
          this.showAlertMessage('Terminal Rate creation successful', 'success')
        })
        .catch(error => {
          console.log(error)
          this.showAlertMessage('Terminal Rate creation failed', 'error')
        })
        .finally(() => {
          this.isSaving = false
          this.dialog = false
          this.onReset()
        })
    },
    onReset () {
      this.$refs.terminalBenefitRate.reset()
    },
    getInputLabel (item) {
      salaryComponentService.getLabel(item.id).then(result => {
        this.label = result.data.label
      }).catch(error => {
        console.log(error)
      })
    }
  },
  mounted () {
    benefitService.getExitReasons().then(result => {
      this.exitReasons = result.data
    })
    salaryComponentService.getAllSalaryBenefits().then(result => {
      this.salaryComponents = result.data
    })
  }
}
</script>
<style scoped>
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
